
import { defineComponent, ref, onMounted } from "vue"
import { Actions } from "@/store/enums/StoreEnums"
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb"

export default defineComponent({
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("活动列表", ["活动管理;events"])
    });

    const store = useStore();
    const router = useRouter();
    const tableData = ref([]);

    const formData = ref({
      name: '',
      enabled: ''
    });

    const page = ref({
      currentPage: 1,
      pageSize: 10,
      totalResult: 0,
    });

    const query = () => {
      store
        .dispatch(Actions.GET_EVENTS, {
          ...formData.value,
          ...page.value,
        })
        .then(() => {
          tableData.value = store.getters.currentEvents
          page.value.totalResult = store.getters.currentEventCount
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    };

    query()

    const submitEvent = () => {
      query()
    }

    const newEvent = () => {
      router.push({ name: "event_new" })
    }

    const onPageChange = (e) => {
      page.value.currentPage = e.currentPage
      query()
    }

    const onCancel = () => {
      formData.value.name = ''
      formData.value.enabled = ''
    }

    return {
      tableData,
      formData,
      submitEvent,
      newEvent,
      page,
      onPageChange,
      onCancel
    }
  },
})
